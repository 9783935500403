import { useAuth0 } from '@auth0/auth0-react';

import MarketingNavView from '@/components/MarketingNav/MarketingNavView';

const defaultProps = {};

type MarketingNavContainerProps = {
  organizationId: string | number;
} & typeof defaultProps;

export function MarketingNavContainer({
  organizationId,
}: MarketingNavContainerProps) {
  const { isAuthenticated } = useAuth0();
  return (
    <MarketingNavView
      isAuthenticated={isAuthenticated}
      organizationId={organizationId}
    />
  );
}

MarketingNavContainer.defaultProps = defaultProps;

export default MarketingNavContainer;
