import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LanguageIcon from '@mui/icons-material/Language';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import ButtonDropdownMenu from '@/components/core/ButtonDropdownMenu';

const defaultProps = {};

type LanguageSelectViewProps = {
  isMenuOpen: boolean;
  localeId: string | number;
  locales: Array<any>;
  onMenuItemClick: Function;
  setIsMenuOpen: Function;
} & typeof defaultProps;

export function LanguageSelectView({
  isMenuOpen = false,
  localeId,
  locales,
  onMenuItemClick,
  setIsMenuOpen,
}: LanguageSelectViewProps) {
  const { t } = useTranslation();
  const locale = locales.find((locale) => {
    return locale.code === localeId;
  });
  return (
    <ButtonDropdownMenu
      anchorOrigin={{
        vertical: `bottom`,
        horizontal: `left`,
      }}
      aria-label={t(`language-select.select-a-language`)}
      buttonProps={{
        size: `small`,
        startIcon: <LanguageIcon />,
        variant: `text`,
      }}
      disabled={false}
      isOpen={isMenuOpen}
      label={locale?.name}
      onClick={() => setIsMenuOpen(true)}
      onClose={() => setIsMenuOpen(false)}
      transformOrigin={{
        vertical: `top`,
        horizontal: `left`,
      }}
    >
      <Box sx={{ px: 1.5, py: 1.25, maxWidth: 250 }}>
        <Typography variant="body2" sx={{ color: `text.secondary` }}>
          {t(`language-select.only-english`)}
        </Typography>
      </Box>
      {locales &&
        locales.map((locale) => (
          <MenuItem
            disabled={locale.code === localeId}
            disableRipple
            key={locale.code}
            onClick={() => {
              return onMenuItemClick(locale);
            }}
          >
            <ListItemIcon>
              {locale.code === localeId ? <ChevronRightIcon /> : null}
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={
                locale.code === localeId ? { color: `grey.A700` } : {}
              }
            >
              {locale.name}
            </ListItemText>
          </MenuItem>
        ))}
    </ButtonDropdownMenu>
  );
}

LanguageSelectView.defaultProps = defaultProps;

export default LanguageSelectView;
