import { ReactNode, useEffect, useRef, useState } from 'react';

import Button, { ButtonProps } from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ActionsMenu from '@/components/core/ActionsMenu';

const defaultProps = {};

type ButtonDropdownMenuProps = {
  buttonProps: ButtonProps;
  children: ReactNode;
  disabled: boolean;
  label: string;
  isOpen: boolean;
  onClick: any;
  onClose: any;
} & typeof defaultProps;

export function ButtonDropdownMenu({
  buttonProps,
  children,
  disabled,
  isOpen = false,
  label,
  onClick,
  onClose,
  ...props
}: ButtonDropdownMenuProps) {
  const buttonRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    setAnchorEl(buttonRef.current);
  }, [buttonRef]);

  return (
    <>
      <Button
        aria-controls={isOpen ? `button-dropdown-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? `true` : undefined}
        disabled={disabled}
        disableRipple
        endIcon={<KeyboardArrowDownIcon />}
        onClick={onClick}
        ref={buttonRef}
        variant="contained"
        {...buttonProps}
      >
        {label}
      </Button>
      <ActionsMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: `bottom`,
          horizontal: `right`,
        }}
        elevation={1}
        keepMounted
        onClose={onClose}
        open={Boolean(isOpen)}
        transformOrigin={{
          vertical: `top`,
          horizontal: `right`,
        }}
        {...props}
      >
        {children}
      </ActionsMenu>
    </>
  );
}

ButtonDropdownMenu.defaultProps = defaultProps;

export default ButtonDropdownMenu;
