import { useAuth0 } from '@auth0/auth0-react';
import { useLocalization } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';

import SignUpHeroView from '@/components/SignUpHero/SignUpHeroView';

const defaultProps = {};

type SignUpHeroContainerProps = {} & typeof defaultProps;

// eslint-disable-next-line no-empty-pattern
export function SignUpHeroContainer({}: SignUpHeroContainerProps) {
  const { loginWithRedirect } = useAuth0();
  const { config: locales, locale: localeId } = useLocalization();
  const { t } = useTranslation();

  const { hrefLang } = locales.find(
    (locale: string) => locale.code === localeId,
  );
  const TIER_ID_TRIAL = `trial`;
  const TIER_ID_PREMIUM = `premium`;

  // A fair warning to whomever finds this code next. This is nearly setup
  // to be ready for a new locale but this controller needs to useEffect
  // to re-render the price based on the hrefLang. This means that we would want
  // to watch for changes to the locale, re-find the hrefLang, map new tier
  // pricing strings, then pass that down. For some odd reason, a useEffect map
  // based on the locale will not cause a re-render in the view component.
  // Best of luck figuring this out when the time comes, my friend.
  // -cb
  const tiers = [
    {
      id: TIER_ID_TRIAL,
      name: t(`sign-up.hero.pricing.trial.free`),
      description: t(`sign-up.hero.pricing.trial.get-started-with-access`),
      price: new Intl.NumberFormat(hrefLang, {
        currency: `USD`,
        minimumFractionDigits: 0,
        style: `currency`,
      }).format(0),
      unit: t(`sign-up.hero.pricing.trial.free-trial-for-a-month`),
      action: t(`sign-up.hero.pricing.trial.get-started`),
      route: `/sign-up`,
    },
    {
      id: TIER_ID_PREMIUM,
      name: t(`sign-up.hero.pricing.premium.premium`),
      description: t(`sign-up.hero.pricing.premium.best-for`),
      price: new Intl.NumberFormat(hrefLang, {
        currency: `USD`,
        minimumFractionDigits: 0,
        style: `currency`,
      }).format(6),
      unit: t(`sign-up.hero.pricing.premium.per-site-per-month`),
      action: t(`sign-up.hero.pricing.premium.upgrade-now`),
      route: `/sign-up`,
    },
  ];

  const features = [
    {
      name: t(`sign-up.hero.features.blueprint-templates`),
      tiers: [
        {
          tier: TIER_ID_TRIAL,
          enabled: true,
        },
        {
          tier: TIER_ID_PREMIUM,
          enabled: true,
        },
      ],
    },
    {
      name: t(`sign-up.hero.features.custom-domain`),
      tiers: [
        {
          tier: TIER_ID_TRIAL,
          enabled: false,
        },
        {
          tier: TIER_ID_PREMIUM,
          enabled: true,
        },
      ],
    },
    {
      name: t(`sign-up.hero.features.support`),
      tiers: [
        {
          tier: TIER_ID_TRIAL,
          enabled: false,
        },
        {
          tier: TIER_ID_PREMIUM,
          enabled: true,
        },
      ],
    },
  ];

  return (
    <SignUpHeroView
      features={features}
      onSignInClick={loginWithRedirect}
      tiers={tiers}
    />
  );
}

SignUpHeroContainer.defaultProps = defaultProps;

export default SignUpHeroContainer;
