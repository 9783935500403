import { useAuth0 } from '@auth0/auth0-react';

import MarketingFrame from '@/components/MarketingFrame';
import SignUpHero from '@/components/SignUpHero';

export function Index() {
  const { user } = useAuth0();

  return (
    <MarketingFrame organizationId={user?.nickname}>
      <SignUpHero />
    </MarketingFrame>
  );
}

export default Index;
