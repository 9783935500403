import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';
import { useAuth0 } from '@auth0/auth0-react';

import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

import LogoLink from '@/components/LogoLink';
import OrganizationsSelect from '@/components/OrganizationsSelect';
import UserDropdown from '@/components/UserDropdown';

const defaultProps = {};

type MarketingNavViewProps = {
  isAuthenticated: boolean;
  organizationId: string | number;
} & typeof defaultProps;

const MarketingNavLink = styled(Link)(({ theme }) => ({
  alignSelf: `stretch`,
  alignItems: `center`,
  borderBottom: `2px solid transparent`,
  color: theme.palette.grey[500],
  display: `flex`,
  fontSize: `1.25rem`,
  fontWeight: 500,
  letterSpacing: -0.35,
  textDecoration: `none`,
  [`:hover`]: {
    color: theme.palette.grey.A700,
    textDecoration: `none`,
  },
  [`&.active`]: {
    color: theme.palette.common.black,
  },
}));

export function MarketingNavView({
  isAuthenticated,
  organizationId,
}: MarketingNavViewProps) {
  const { t } = useTranslation();
  const { loginWithRedirect } = useAuth0();
  return (
    <AppBar
      elevation={0}
      sx={{ backgroundColor: `transparent`, mt: 2.5, p: 0 }}
      position="static"
    >
      <Grid alignItems="center" container>
        <Grid item xs={1}>
          <Box sx={{ maxWidth: `115px` }}>
            <LogoLink fontSize={`large`} />
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Box sx={{ flexGrow: 1 }}>
            <Toolbar
              sx={{
                alignItems: `stretch`,
                minHeight: { xs: 0, sm: 0 },
              }}
            >
              {isAuthenticated && (
                <Stack
                  component="nav"
                  direction="row"
                  spacing={3}
                  sx={{ display: `flex` }}
                >
                  <MarketingNavLink
                    activeClassName="active"
                    component={GatsbyLink}
                    partiallyActive={true}
                    to={`/organizations/${organizationId}/sites`}
                  >
                    {t(`sites.sites`)}
                  </MarketingNavLink>
                  <MarketingNavLink
                    activeClassName="active"
                    component={GatsbyLink}
                    partiallyActive={true}
                    to={`/organizations/${organizationId}/domains`}
                  >
                    {t(`domains.domains`)}
                  </MarketingNavLink>
                  <MarketingNavLink
                    activeClassName="active"
                    component={GatsbyLink}
                    partiallyActive={true}
                    to={`/organizations/${organizationId}/blueprints`}
                  >
                    {t(`blueprints.blueprints`)}
                  </MarketingNavLink>
                </Stack>
              )}
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  display: `flex`,
                  alignSelf: `center`,
                }}
              >
                {false && (
                  <OrganizationsSelect organizationId={organizationId} />
                )}
                {isAuthenticated ? (
                  <UserDropdown />
                ) : (
                  <Button variant="text" onClick={loginWithRedirect}>
                    {t(`sign-in.sign-in`)}
                  </Button>
                )}
              </Box>
            </Toolbar>
          </Box>
        </Grid>
      </Grid>
    </AppBar>
  );
}

MarketingNavView.defaultProps = defaultProps;

export default MarketingNavView;
