import { ReactNode } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import MarketingNav from '@/components/MarketingNav';

const defaultProps = {};

type AppFrameViewProps = {
  children: ReactNode;
  organizationId: string | number;
} & typeof defaultProps;

export function AppFrameView({ children, organizationId }: AppFrameViewProps) {
  return (
    <Box mb={10}>
      <Box
        sx={{
          borderBottom: 0,
          borderColor: `grey.A200`,
        }}
      >
        <Container maxWidth="xl">
          <MarketingNav organizationId={organizationId} />
        </Container>
      </Box>
      <Box>
        <Container maxWidth="xl">
          <Grid container justifyContent="center">
            <Grid item xs={10}>
              <Box component={`main`} mx={3} pt={2}>
                {children}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

AppFrameView.defaultProps = defaultProps;

export default AppFrameView;
