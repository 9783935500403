import { Link as GatsbyLink } from 'gatsby';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

import ButtonDropdownMenu from '@/components/core/ButtonDropdownMenu';

const defaultProps = {};

type OrganizationsSelectViewProps = {
  isMenuOpen: boolean;
  organizationId: string | number;
  organizations: Array<any>;
  setIsMenuOpen: Function;
} & typeof defaultProps;

export function OrganizationsSelectView({
  isMenuOpen = false,
  organizationId,
  organizations,
  setIsMenuOpen,
}: OrganizationsSelectViewProps) {
  const organization = organizations.find((organization) => {
    return organization.id === organizationId;
  });
  return (
    <ButtonDropdownMenu
      aria-label="Organization"
      buttonProps={{ size: `small`, variant: `text` }}
      disabled={false}
      isOpen={isMenuOpen}
      label={organization?.name}
      onClick={() => setIsMenuOpen(true)}
      onClose={() => setIsMenuOpen(false)}
    >
      {organizations &&
        organizations.map((organization) => (
          <MenuItem
            component={GatsbyLink}
            disabled={organization.id === organizationId}
            disableRipple
            key={organization.id}
            to={`/organizations/${organization.id}`}
          >
            <ListItemIcon>
              {organization.id === organizationId ? <ChevronRightIcon /> : null}
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={
                organization.id === organizationId ? { color: `grey.A700` } : {}
              }
            >
              {organization.name}
            </ListItemText>
          </MenuItem>
        ))}
    </ButtonDropdownMenu>
  );
}

OrganizationsSelectView.defaultProps = defaultProps;

export default OrganizationsSelectView;
