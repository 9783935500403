import { useState } from 'react';

import OrganizationsSelectView from '@/components/OrganizationsSelect/OrganizationsSelectView';

const defaultProps = {};

type OrganizationsSelectContainerProps = {
  organizationId: string | number;
} & typeof defaultProps;

export function OrganizationsSelectContainer({
  organizationId,
}: OrganizationsSelectContainerProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const organizations = [
    {
      id: 1,
      name: `QR8R`,
    },
    {
      id: 2,
      name: `Estate Coffee Co.`,
    },
  ];
  return (
    <OrganizationsSelectView
      isMenuOpen={isMenuOpen}
      organizationId={organizationId}
      organizations={organizations}
      setIsMenuOpen={setIsMenuOpen}
    />
  );
}

OrganizationsSelectContainer.defaultProps = defaultProps;

export default OrganizationsSelectContainer;
