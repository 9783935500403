import { useState } from 'react';
import { useLocalization } from 'gatsby-theme-i18n';

import LanguageSelectView from '@/components/LanguageSelect/LanguageSelectView';

const defaultProps = {};

type LanguageSelectContainerProps = {} & typeof defaultProps;

// eslint-disable-next-line no-empty-pattern
export function LanguageSelectContainer({}: LanguageSelectContainerProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { locale: localeId, config: locales } = useLocalization();
  return (
    <LanguageSelectView
      isMenuOpen={isMenuOpen}
      localeId={localeId}
      locales={locales}
      onMenuItemClick={() => {}}
      setIsMenuOpen={setIsMenuOpen}
    />
  );
}

LanguageSelectContainer.defaultProps = defaultProps;

export default LanguageSelectContainer;
