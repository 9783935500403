import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Divider from '@mui/material/Divider';
import EastIcon from '@mui/icons-material/East';
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import LanguageSelect from '@/components/LanguageSelect';

import UndrawExperienceDesign from '@/assets/svgs/undraw-experience-design.svg';

const defaultProps = {};

type FeatureTier = {
  tier: string;
  enabled: boolean;
};

type Feature = {
  name: string;
  tiers: Array<FeatureTier>;
};

type Tier = {
  id: string;
  name: string;
  description: string;
  price: string;
  unit: string;
  action: string;
  route: string;
};

type SignUpHeroViewProps = {
  features: Array<Feature>;
  onSignInClick: () => void;
  tiers: Array<Tier>;
} & typeof defaultProps;

export function SignUpHeroView({
  features,
  onSignInClick,
  tiers,
}: SignUpHeroViewProps) {
  const { t } = useTranslation();
  return (
    <Box>
      <Box sx={{ mt: 7.5, mb: 5 }}>
        <Grid container spacing={11}>
          <Grid xs={6}>
            <Typography
              variant="h1"
              sx={{ mb: 2, fontSize: `4rem`, letterSpacing: -3 }}
            >
              {t(`sign-up.hero.nobody-needs-a-website`)}
              <Box
                component="span"
                sx={{
                  pl: 2,
                  color: `blue.A400`,
                }}
              >
                {t(`sign-up.hero.everybody-needs-a-landing`)}
              </Box>
            </Typography>
            <Typography
              component="p"
              variant="subtitle1"
              sx={{ mb: 4, pr: 12 }}
            >
              {t(`sign-up.hero.build-simple-sites`)}
            </Typography>
            <Button
              endIcon={<EastIcon />}
              onClick={onSignInClick}
              size="large"
              variant="contained"
            >
              {t(`sign-up.sign-up`)}
            </Button>
          </Grid>
          <Grid xs={6}>
            <Box sx={{ p: 5 }}>
              <UndrawExperienceDesign width="100%" height="auto" />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 5, mb: 5 }}>
        <Grid container>
          <Grid sx={{ p: 3 }} xs={4}></Grid>
          {tiers.length &&
            tiers.map((tier, index) => (
              <Grid
                key={tier.id}
                sx={{
                  backgroundColor: index > 0 ? `grey.50` : `transparent`,
                  p: 3,
                }}
                xs={4}
              >
                <Typography fontWeight="700">{tier.name}</Typography>
                <Typography>{tier.description}</Typography>
                <Box
                  sx={{ alignItems: `center`, display: `flex`, mb: 4, mt: 2 }}
                >
                  <Typography
                    component="span"
                    variant="h4"
                    sx={{ color: index === 0 ? `green.A700` : `` }}
                  >
                    {tier.price}
                  </Typography>
                  <Box sx={{ mx: 0.5 }} />
                  <Typography component="span">– {tier.unit}</Typography>
                </Box>
                <Button
                  endIcon={<EastIcon />}
                  variant={index === 0 ? `outlined` : `contained`}
                  fullWidth
                >
                  {tier.action}
                </Button>
              </Grid>
            ))}
        </Grid>
        {features.length &&
          features.map((feature) => (
            <Box key={feature.name}>
              <Divider />
              <Grid container>
                <Grid xs={4} sx={{ p: 3, py: 1.5 }}>
                  <Typography>{feature.name}</Typography>
                </Grid>
                {feature.tiers.length &&
                  feature.tiers.map((tier, index) => (
                    <Grid
                      key={tier.tier}
                      sx={{
                        backgroundColor: index > 0 ? `grey.50` : `transparent`,
                        p: 3,
                        py: 1.5,
                      }}
                      xs={4}
                    >
                      {tier.enabled ? (
                        <CheckCircleIcon
                          sx={{ color: `green.A700`, fontSize: `1rem` }}
                        />
                      ) : (
                        <CheckCircleIcon
                          sx={{ color: `grey.A200`, fontSize: `1rem` }}
                        />
                      )}
                    </Grid>
                  ))}
              </Grid>
            </Box>
          ))}
      </Box>
      <Box sx={{ mt: 10, mb: 5 }}>
        <Grid container spacing={5}>
          <Grid xs={12} sx={{ display: `flex` }}>
            <IconButton
              aria-label="Instagram"
              href="https://www.instagram.com/nailthelanding"
              rel="noreferrer noopener"
              target="_blank"
            >
              <InstagramIcon />
            </IconButton>
            <Box sx={{ pr: 2 }} />
            <LanguageSelect />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid xs={8}>
            <Typography fontSize="small">
              © Landing Technologies Inc.
            </Typography>
          </Grid>
          <Grid xs={4} sx={{ textAlign: `right` }}>
            <Link fontSize="small" href="#">
              Privacy
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

SignUpHeroView.defaultProps = defaultProps;

export default SignUpHeroView;
